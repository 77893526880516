.container-404 {
  text-align: center;

  &-title {
    @include font-weight(bold);
    color: primary-color(black);
  }

  &-content {
    background-color: primary-color(white);

    & > .btn {
      border-radius: 0;
      padding: 15px 20px;
    }
  }
}
