// sass-lint:disable force-element-nesting

// Headers
//
// Wrapper:
// max-width: 700px;
//
// Markup:
// <h1>Header 1 (36px) - Lorem Ipsum Solor Domet</h1>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
// <h2>Header 2 (30px) - Lorem Ipsum Solor Domet</h2>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
// <h3>Header 3 (26px) - Lorem Ipsum Solor Domet</h3>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
// <h4>Header 4 (18px) - Lorem Ipsum Solor Domet</h4>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
// <h5>Header 5 (16px) - Lorem Ipsum Solor Domet</h5>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
// <h6>Header 6 (16px) - Lorem Ipsum Solor Domet</h6>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
//
// Weight: -99
//
// Styleguide Typography.Headers
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $header-color;
  font-family: $header-font;
  @include font-weight(medium);
  margin: 0 0 20px;
  transition: font-size $ts $te;

  &:last-child {
    margin-bottom: 0;
  }

  &.-center {
    text-align: center;
  }

  a {
    text-decoration: none;
  }
}

// make h1 only black
h1,
.h1 {
  color: primary-color(black);
}

.h1,
.wysiwyg-content h1, {
  @include rem(font-size, 20px);

  @include from(10) {
    @include rem(font-size, 26px);
  }
}

.h2,
.wysiwyg-content h2, {
  @include rem(font-size, 18px);

  @include from(10) {
    @include rem(font-size, 24px);
  }
}

.h3,
.wysiwyg-content h3, {
  @include rem(font-size, 17px);

  @include from(10) {
    @include rem(font-size, 22px);
  }
}

.h4,
.wysiwyg-content h4, {
  @include rem(font-size, 17px);

  @include from(10) {
    @include rem(font-size, 18px);
  }
}

.h5,
.wysiwyg-content h5, {
  @include rem(font-size, 16px);
}

.h6,
.wysiwyg-content h6, {
  @include rem(font-size, 16px);
}

// Paragraphs
//
// Wrapper:
// max-width: 700px;
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. </p>
// <p>Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum gravida et. Integer lectus nisi, facilisis sit amet eleifend nec, pharetra ut augue. Integer quam nunc, consequat nec egestas ac, volutpat ac nisi. </p>
//
// Weight: -98
//
// Styleguide Typography.Paragraphs
p {
  @include rem(font-size, 16px);
  line-height: 1.3;
  margin: 0 0 20px;

  &:last-child {
    margin-bottom: 0;
  }
}


// Unordered Lists
//
// Wrapper:
// max-width: 700px;
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <ul>
//   <li>Lorem ipsum dolor</li>
//   <li>Sit amet, consectetur
//     <ul>
//       <li>Lorem ipsum dolor
//         <ul>
//           <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit</li>
//         </ul>
//       </li>
//       <li>Sit amet, consectetur</li>
//       <li>Adipisicing elit, sed</li>
//       <li>Do eiusmod tempor</li>
//       <li>Incididunt ut labore</li>
//       <li>Et dolore magna</li>
//       <li>Aliqua. Ut enim</li>
//     </ul>
//   </li>
//   <li>Adipisicing elit, sed</li>
//   <li>Do eiusmod tempor</li>
//   <li>Incididunt ut labore</li>
//   <li>Et dolore magna</li>
//   <li>Aliqua. Ut enim</li>
// </ul>
//
// Weight: -97
//
// Styleguide Typography.Unordered Lists

// Ordered Lists
//
// Wrapper:
// max-width: 700px;
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
// <ol>
//   <li>Lorem ipsum dolor</li>
//   <li>Sit amet, consectetur
//     <ul>
//       <li>Lorem ipsum dolor</li>
//       <li>Sit amet, consectetur</li>
//       <li>Adipisicing elit, sed</li>
//       <li>Do eiusmod tempor</li>
//       <li>Incididunt ut labore</li>
//       <li>Et dolore magna</li>
//       <li>Aliqua. Ut enim</li>
//     </ul>
//   </li>
//   <li>Adipisicing elit, sed</li>
//   <li>Do eiusmod tempor</li>
//   <li>Incididunt ut labore</li>
//   <li>Et dolore magna</li>
//   <li>Aliqua. Ut enim</li>
// </ol>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
//
// Weight: -96
//
// Styleguide Typography.Ordered Lists
ol,
ul {
  margin: 0 0 20px;

  &:last-child {
    margin-bottom: 0;
  }

  li {
    @include rem(font-size, 16px);
  }
}
