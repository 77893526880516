// Buttons
//
// :hover    - Hover state
// :focus    - Focus state
// :active   - Active state
// :disabled - Disabled state
//
// Markup:
// <a class="btn {{modifier_class}}" href="#">Link Button</a>
// <button class="{{modifier_class}}">Standard Button</button>
// <input type="submit" class="{{modifier_class}}" value="Submit Button" />
// <input type="reset" class="{{modifier_class}}" value="Reset Button" />
//
// Weight: -98
//
// Styleguide Components.Buttons
button,
.btn,
.button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
  display: inline-block;
  cursor: pointer;
  background: $link-color;
  color: primary-color(white);
  @include rem(font-size, 16px);
  @include font-weight(bold);
  text-align: center;
  text-decoration: none;
  padding: 8px 20px;
  border: 0;
  border-radius: 1000px;
  transition: $ts $te;

  @include hover {
    background: darken(primary-color(blue), 10%);
    color: primary-color(white);
    outline: 0;
    border: 0;
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  //modifiers
  &.-red {
    background: primary-color(red);

    @include hover {
      background: darken(primary-color(red), 10%);
    }
  }

  &.-gray {
    background: primary-color(gray, shade2);

    @include hover {
      background: darken(primary-color(gray, shade2), 10%);
    }
  }

  &.-small {
    padding: 8px;
    @include rem(font-size, 12px);
    @include font-weight(bold);
  }

  &.-large {
    display: block;
    @include rem(font-size, 20px);
    padding: 20px 45px;
    border-radius: 3px;
  }

  &.-plus {
    display: inline-flex;
    align-items: center;
    @include icon(plus) {
      @include rem(font-size, 15px);
      margin-right: 4px;
    }
  }

  &.-copy {
    display: inline-flex;
    align-items: center;
    @include icon(copy);

    &.-copied {
      @include icon(check);
    }

    &::before {
      @include rem(font-size, 15px);
      margin-right: 4px;
    }
  }
}
