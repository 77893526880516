// Site Footer
//
// .-modifier - Modifier description
//
// Markup: site-footer.twig
//
// Styleguide Components.Site Footer
.site-footer {
  background: primary-color(white);
  margin: 50px 0 0;

  &-inner,
  .copyright { // sass-lint:disable-line force-element-nesting
    max-width: 1000px;
    padding: 20px 20px 0;
    margin: 0 auto;

    &:last-child {
      padding-bottom: 20px;
    }

    @include from(7) {

      &:last-child {
        padding-bottom: 50px;
      }
    }

    @include from(10) {
      padding: 20px 0 0;

      &:first-child {
        padding-top: 50px;
      }

      &:last-child {
        padding-bottom: 50px;
      }
    }
  }

  p {
    @include rem(font-size, 11px);
  }

  .copyright {

    .inner {

      @include from(7) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .text-side {
      margin-bottom: 10px;

      @include from(7) {
        margin-bottom: 0;
        flex: 0 0 50%;
      }
    }

    .link-side {

      @include from(7) {
        flex: 0 0 50%;
        text-align: right;
      }

      & > ul {
        @include kill-bullets;

        @include from(7) {
          display: flex;
          justify-content: flex-end;
        }

        li {
          margin-bottom: 5px;

          @include from(7) {
            margin-bottom: 0;
            margin-right: 10px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
