// Modal Admin Tour Operator
//
// .-modifier - Modifier description
//
// Markup: modal-admin-tour-operator.twig
//
// Styleguide Components.Modal Admin Tour Operator
.modal-admin-tour-operator {
  position: relative;
  background: primary-color(white);
  max-width: 720px;
  padding: 0 20px 20px;
  margin: 0 auto;

  @include from(7) {
    padding: 0 45px 20px;
  }

  &-header {
    background: secondary-color(light-blue, tint1);
    padding: 20px 40px 20px 45px;
    @include font-weight(bold);
    margin: 20px -20px;

    @include from(5) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include from(7) {
      margin: 20px -45px;
    }
  }

  .btn,
  .button {
    margin-top: 20px;
  }
}
