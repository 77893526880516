// Grayscale Colors
//
// Color:
// primary-color(gray, shade1)     : #333 - Dark Gray
// primary-color(gray)           : #666 - Gray
// primary-color(gray, tint1)    : #ccc - Light Gray
//
// Styleguide Colors.Grayscale Colors

// Primary Colors
//
// Color:
// primary-color(orange, shade1)   : #c3420b - Dark Orange
// primary-color(orange)         : #f2530f - Orange
// primary-color(orange, tint1)  : #f5763f - Light Orange
//
// Styleguide Colors.Primary Colors
$primarycolors: (
  white: (
    base: #fff
  ),
  black: (
    base: #000
  ),
  gray: (
    base: #d1d1d1,
    tint1: #f3f2f2,
    shade1: #919191,
    shade2: #3b3b3b
  ),
  red: (
    base: #e21836
  ),
  blue: (
    base: #173a8c,
    shade1: #0d2458
  )
);

// Secondary Colors
//
// Color:
// secondary-color(red, shade1)   : #cc0000 - Red - Shade 1
// secondary-color(red)         : #ff0000 - Red
// secondary-color(red, tint1)  : #ff3333 - Red - Tint 1
//
// Styleguide Colors.Secondary Colors
$secondarycolors: (
  light-blue: (
    base: #9ddbf3,
    tint1: #cbecf9
  ),
  green: (
    base: #25931b
  )
);
