// Training Video
//
// .-modifier - Modifier description
//
// Markup: training-video.twig
//
// Styleguide Components.Training Video
.training-video {
  max-width: 720px;
  background-color: primary-color(black);
  margin: 0 auto;
}
