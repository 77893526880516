// Registration Promo
//
// .-modifier - Modifier description
//
// Markup: registration-promo.twig
//
// Styleguide Components.Registration Promo
.registration-promo {

  &-box {
    position: relative;
    text-align: center;
    padding: 20px;
    border: 1px solid primary-color(gray);
    margin-bottom: 30px;

    @include from(7) {
      padding: 26px;
    }

    .btn,
    .button {
      max-width: 240px;
      margin: 0 auto;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: secondary-color(light-blue, tint1);
    }
  }

  &-why-register {
    .h3 {
      position: relative;
      padding-bottom: 20px;

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100px;
        height: 3px;
        background: primary-color(gray);
      }
    }
  }
}
