.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > * {
    flex: none;
  }

  & > main {
    flex: 1 0 auto;
  }
}
