// Travel Retailer Employee Listing
//
// .-modifier - Modifier description
//
// Markup: travel-retailer-employee-listing.twig
//
// Styleguide Components.Travel Retailer Employee Listing
.travel-retailer-employee-listing {

  //header for mobile only
  &-item-mobile-header {
    display: block;
    padding: 5px;
    @include font-weight(bold);
    @include rem(font-size, 12px);
    background-color: primary-color(gray);
    margin-bottom: 4px;

    @include from(5) {
      display: none;
    }
  }

  &-header {
    display: none;

    @include from(5) {
      display: flex;
      justify-content: space-between;
      padding: 10px 5px;
      background: primary-color(gray);
      @include font-weight(bold);
      @include rem(font-size, 14px);
    }

    &-name {
      width: 25%;
      padding-left: 5px;
    }

    &-email {
      width: 45%;
      padding-left: 10px;
    }

    &-date {
      width: 17%;
      padding-right: 5px;
      text-align: right;
    }
  }

  &-item {
    border-bottom: 1px solid primary-color(gray);
    margin-bottom: 20px;

    @include from(5) {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      margin: 0;

      & > div {
        display: flex;
        align-items: center;
      }
    }

    &:last-child {
      border: 0;
    }

    &:nth-child(even) {
      background: primary-color(gray, tint1);
    }

    &-content {
      padding: 0 5px;
      margin-bottom: 10px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      @include rem(font-size, 12px);

      @include from(5) {
        margin: 0;
      }

      @include from(7) {
        padding: 0 10px;
      }
    }

    &-name {
      @include from(5) {
        width: 25%;
      }
    }

    &-email {
      @include from(5) {
        width: 45%;
      }
    }

    &-date {
      @include from(5) {
        width: 17%;
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
}
