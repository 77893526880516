@charset "UTF-8";
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input {
  overflow: visible;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

menu {
  display: block;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

*::after, *::before {
  box-sizing: border-box;
}

body,
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #3b3b3b;
  font-family: "Roboto", sans-serif;
  font-size: 100%;
  background-color: #9ddbf3;
  min-width: 320px;
  min-height: 100vh;
}

hr {
  background-color: transparent;
  border: 0;
  border-bottom: solid 1px #f3f2f2;
  margin: 40px auto;
  clear: both;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.video-container .placeholder,
.video-container embed,
.video-container iframe,
.video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sr-text {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  position: absolute;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.content-container {
  background: #fff;
  max-width: 720px;
  padding: 30px 20px;
  margin: 0 auto;
}

@media (min-width: 767px) {
  .content-container {
    padding: 45px 100px;
  }
}

@media (min-width: 767px) {
  .content-container.-less-padding {
    padding: 45px;
  }
}

[class^='icon-']::before,
[class*=' icon-']::before {
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #173a8c;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 0 0 20px;
  transition: font-size 0.2s ease-in-out;
}

.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

.h1.-center,
.h2.-center,
.h3.-center,
.h4.-center,
.h5.-center,
.h6.-center,
h1.-center,
h2.-center,
h3.-center,
h4.-center,
h5.-center,
h6.-center {
  text-align: center;
}

.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
}

h1,
.h1 {
  color: #000;
}

.h1,
.wysiwyg-content h1 {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 1040px) {
  .h1,
  .wysiwyg-content h1 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.h2,
.wysiwyg-content h2 {
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 1040px) {
  .h2,
  .wysiwyg-content h2 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.h3,
.wysiwyg-content h3 {
  font-size: 17px;
  font-size: 1.0625rem;
}

@media (min-width: 1040px) {
  .h3,
  .wysiwyg-content h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.h4,
.wysiwyg-content h4 {
  font-size: 17px;
  font-size: 1.0625rem;
}

@media (min-width: 1040px) {
  .h4,
  .wysiwyg-content h4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.h5,
.wysiwyg-content h5 {
  font-size: 16px;
  font-size: 1rem;
}

.h6,
.wysiwyg-content h6 {
  font-size: 16px;
  font-size: 1rem;
}

p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  margin: 0 0 20px;
}

p:last-child {
  margin-bottom: 0;
}

ol,
ul {
  margin: 0 0 20px;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

ol li,
ul li {
  font-size: 16px;
  font-size: 1rem;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

fieldset legend {
  display: block;
  width: 100%;
}

label {
  display: block;
  padding: 10px 0;
}

.error-message {
  color: #fff;
  background-color: #e21836;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 3px 4px;
  margin-top: -15px;
  margin-bottom: 15px;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='search'],
input[type='tel'],
select,
textarea {
  display: block;
  width: 100%;
  height: 43px;
  color: #666;
  font-size: 16px;
  padding: 12px 15px;
  border: 1px solid #d1d1d1;
  border-radius: 0;
  box-shadow: none;
  margin: 0 0 15px;
  -webkit-appearance: none;
  transition: background-color 0.2s ease-in-out;
}

@media (min-width: 600px) {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  select,
  textarea {
    font-size: 14px;
    font-size: 0.875rem;
    height: 40px;
  }
}

input[type='text']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='search']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}

input[type='text']::-ms-placeholder,
input[type='email']::-ms-placeholder,
input[type='password']::-ms-placeholder,
input[type='search']::-ms-placeholder,
input[type='tel']::-ms-placeholder,
select::-ms-placeholder,
textarea::-ms-placeholder {
  opacity: 1;
}

input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='search']::placeholder,
input[type='tel']::placeholder,
select::placeholder,
textarea::placeholder {
  opacity: 1;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
select:focus,
textarea:focus {
  box-shadow: inset 0 0 0 2px #9ddbf3;
  border: 1px solid #9ddbf3;
  outline: none;
}

input[type='text']:focus::-webkit-input-placeholder,
input[type='email']:focus::-webkit-input-placeholder,
input[type='password']:focus::-webkit-input-placeholder,
input[type='search']:focus::-webkit-input-placeholder,
input[type='tel']:focus::-webkit-input-placeholder,
select:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: #666;
}

input[type='text']:focus::-ms-placeholder,
input[type='email']:focus::-ms-placeholder,
input[type='password']:focus::-ms-placeholder,
input[type='search']:focus::-ms-placeholder,
input[type='tel']:focus::-ms-placeholder,
select:focus::-ms-placeholder,
textarea:focus::-ms-placeholder {
  color: #666;
}

input[type='text']:focus::placeholder,
input[type='email']:focus::placeholder,
input[type='password']:focus::placeholder,
input[type='search']:focus::placeholder,
input[type='tel']:focus::placeholder,
select:focus::placeholder,
textarea:focus::placeholder {
  color: #666;
}

input[type='text'].-invalid,
input[type='email'].-invalid,
input[type='password'].-invalid,
input[type='search'].-invalid,
input[type='tel'].-invalid,
select.-invalid,
textarea.-invalid {
  border: 1px solid #e21836;
}

textarea {
  min-height: 200px;
  resize: vertical;
}

select {
  display: block;
  background-color: #fff;
  background-image: url("../images/select-arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 15px center;
  text-decoration: none;
  appearance: none;
  padding-right: 35px;
}

select:focus {
  outline: none;
}

select:hover {
  cursor: pointer;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #666;
}

select::-ms-expand {
  display: none;
}

.checks,
.radios {
  margin: 25px 0;
}

.checks legend,
.radios legend {
  margin-bottom: 8px;
}

.checks .check,
.checks .radio,
.radios .check,
.radios .radio {
  position: relative;
  margin-bottom: 15px;
}

.checks label,
.radios label {
  margin: 2px 0 0;
  padding: 0 0 0 32px;
  width: auto;
  position: relative;
}

.checks label::before,
.radios label::before {
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
  color: transparent;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  border: 1px solid #d1d1d1;
  transition: color 0.2s ease-in-out;
}

.checks label:active, .checks label:focus, .checks label:hover,
.radios label:active,
.radios label:focus,
.radios label:hover {
  cursor: pointer;
}

.checks label:active::before, .checks label:focus::before, .checks label:hover::before,
.radios label:active::before,
.radios label:focus::before,
.radios label:hover::before {
  color: rgba(23, 58, 140, 0.3);
}

.checks input[type='checkbox'],
.checks input[type='radio'],
.radios input[type='checkbox'],
.radios input[type='radio'] {
  display: none;
}

.checks input[type='checkbox']:checked ~ label::before,
.checks input[type='radio']:checked ~ label::before,
.radios input[type='checkbox']:checked ~ label::before,
.radios input[type='radio']:checked ~ label::before {
  color: #173a8c;
}

.checks input[type='checkbox'] ~ label::before,
.radios input[type='checkbox'] ~ label::before {
  content: "";
}

.checks input[type='radio'] ~ label::before,
.radios input[type='radio'] ~ label::before {
  content: "";
  border-radius: 50%;
}

.checks .error-message,
.radios .error-message {
  position: absolute;
  margin-left: 32px;
  bottom: 0;
  transform: translateY(calc(100% + 25px));
}

.form-section {
  margin-bottom: 40px;
}

@media (min-width: 500px) {
  .form-row.-two-up {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 500px) {
  .form-row.-two-up .form-row-element-wrapper {
    width: 48%;
  }
}

@media (min-width: 500px) {
  .form-row.-two-up .form-row-element-wrapper.-divided {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .form-row.-two-up .form-row-element-wrapper.-divided .state {
    width: 40%;
  }
  .form-row.-two-up .form-row-element-wrapper.-divided .zip {
    width: 52%;
  }
}

#forgot-password {
  margin-top: 10px;
}

.identity-form > h1,
.identity-form > h4 {
  margin-bottom: 10px;
}

.identity-form > hr {
  margin: 10px 0;
}

.identity-form button[type='submit'] {
  margin-top: 15px;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-container > * {
  flex: none;
}

.page-container > main {
  flex: 1 0 auto;
}

.container-404 {
  text-align: center;
}

.container-404-title {
  font-weight: 700;
  color: #000;
}

.container-404-content {
  background-color: #fff;
}

.container-404-content > .btn {
  border-radius: 0;
  padding: 15px 20px;
}

a {
  display: inline-block;
  color: #173a8c;
  text-decoration: none;
  border: 1px solid transparent;
  transition: 0.2s ease-in-out;
}

a:active, a:focus, a:hover {
  color: #102860;
  border-bottom: 1px solid #173a8c;
  outline: 0;
}

.wysiwyg-content a {
  display: inline;
}

a:disabled, a[disabled] {
  opacity: .5;
  pointer-events: none;
}

button,
.btn,
.button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
  display: inline-block;
  cursor: pointer;
  background: #173a8c;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  padding: 8px 20px;
  border: 0;
  border-radius: 1000px;
  transition: 0.2s ease-in-out;
}

button:active, button:focus, button:hover,
.btn:active,
.btn:focus,
.btn:hover,
.button:active,
.button:focus,
.button:hover,
input[type='submit']:active,
input[type='submit']:focus,
input[type='submit']:hover,
input[type='reset']:active,
input[type='reset']:focus,
input[type='reset']:hover,
input[type='button']:active,
input[type='button']:focus,
input[type='button']:hover {
  background: #102860;
  color: #fff;
  outline: 0;
  border: 0;
}

button:disabled,
.btn:disabled,
.button:disabled,
input[type='submit']:disabled,
input[type='reset']:disabled,
input[type='button']:disabled {
  opacity: .5;
  pointer-events: none;
}

button.-red,
.btn.-red,
.button.-red,
input[type='submit'].-red,
input[type='reset'].-red,
input[type='button'].-red {
  background: #e21836;
}

button.-red:active, button.-red:focus, button.-red:hover,
.btn.-red:active,
.btn.-red:focus,
.btn.-red:hover,
.button.-red:active,
.button.-red:focus,
.button.-red:hover,
input[type='submit'].-red:active,
input[type='submit'].-red:focus,
input[type='submit'].-red:hover,
input[type='reset'].-red:active,
input[type='reset'].-red:focus,
input[type='reset'].-red:hover,
input[type='button'].-red:active,
input[type='button'].-red:focus,
input[type='button'].-red:hover {
  background: #b4132b;
}

button.-gray,
.btn.-gray,
.button.-gray,
input[type='submit'].-gray,
input[type='reset'].-gray,
input[type='button'].-gray {
  background: #3b3b3b;
}

button.-gray:active, button.-gray:focus, button.-gray:hover,
.btn.-gray:active,
.btn.-gray:focus,
.btn.-gray:hover,
.button.-gray:active,
.button.-gray:focus,
.button.-gray:hover,
input[type='submit'].-gray:active,
input[type='submit'].-gray:focus,
input[type='submit'].-gray:hover,
input[type='reset'].-gray:active,
input[type='reset'].-gray:focus,
input[type='reset'].-gray:hover,
input[type='button'].-gray:active,
input[type='button'].-gray:focus,
input[type='button'].-gray:hover {
  background: #222222;
}

button.-small,
.btn.-small,
.button.-small,
input[type='submit'].-small,
input[type='reset'].-small,
input[type='button'].-small {
  padding: 8px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
}

button.-large,
.btn.-large,
.button.-large,
input[type='submit'].-large,
input[type='reset'].-large,
input[type='button'].-large {
  display: block;
  font-size: 20px;
  font-size: 1.25rem;
  padding: 20px 45px;
  border-radius: 3px;
}

button.-plus,
.btn.-plus,
.button.-plus,
input[type='submit'].-plus,
input[type='reset'].-plus,
input[type='button'].-plus {
  display: inline-flex;
  align-items: center;
}

button.-plus::before,
.btn.-plus::before,
.button.-plus::before,
input[type='submit'].-plus::before,
input[type='reset'].-plus::before,
input[type='button'].-plus::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-size: 0.9375rem;
  margin-right: 4px;
}

button.-copy,
.btn.-copy,
.button.-copy,
input[type='submit'].-copy,
input[type='reset'].-copy,
input[type='button'].-copy {
  display: inline-flex;
  align-items: center;
}

button.-copy::before,
.btn.-copy::before,
.button.-copy::before,
input[type='submit'].-copy::before,
input[type='reset'].-copy::before,
input[type='button'].-copy::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button.-copy.-copied::before,
.btn.-copy.-copied::before,
.button.-copy.-copied::before,
input[type='submit'].-copy.-copied::before,
input[type='reset'].-copy.-copied::before,
input[type='button'].-copy.-copied::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button.-copy::before,
.btn.-copy::before,
.button.-copy::before,
input[type='submit'].-copy::before,
input[type='reset'].-copy::before,
input[type='button'].-copy::before {
  font-size: 15px;
  font-size: 0.9375rem;
  margin-right: 4px;
}

.admin-company-selection-form-keyword {
  position: relative;
}

.admin-company-selection-form-keyword .clear-filter {
  position: absolute;
  display: flex;
  align-items: center;
  right: 5px;
  top: 41px;
  color: #3b3b3b;
  background-color: #d1d1d1;
  padding: 8px;
  border: 0;
  border-radius: 1000px;
  font-size: 13px;
  font-size: 0.8125rem;
}

.admin-company-selection-form-keyword .clear-filter::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  font-size: 0.75rem;
  margin-top: 2px;
  margin-right: 4px;
}

.admin-company-selection-form-keyword .clear-filter:active, .admin-company-selection-form-keyword .clear-filter:focus, .admin-company-selection-form-keyword .clear-filter:hover {
  border: 0;
  background-color: #b8b8b8;
}

.admin-company-selection-result-bar {
  background: #cbecf9;
  padding: 10px 20px;
  font-weight: 700;
  margin: 20px -20px;
}

@media (min-width: 767px) {
  .admin-company-selection-result-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 100px;
    margin: 20px -100px;
  }
}

@media (max-width: 799px) {
  .admin-company-selection-result-bar-number {
    margin-bottom: 10px;
  }
}

.admin-company-selection-result-bar-actions .btn,
.admin-company-selection-result-bar-actions .button {
  font-size: 12px;
}

.admin-company-selection-result-listing-item {
  padding: 5px 0;
  border-top: 1px solid #d1d1d1;
}

@media (min-width: 500px) {
  .admin-company-selection-result-listing-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.admin-company-selection-result-listing-item:first-child {
  border: 0;
}

.admin-company-selection-result-listing-item-name {
  width: 80%;
  margin-bottom: 10px;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media (min-width: 767px) {
  .admin-company-selection-result-listing-item-name {
    padding-right: 20px;
    margin: 0;
  }
}

.admin-company-selection-result-listing-item-actions {
  display: flex;
  align-items: center;
}

@media (min-width: 767px) {
  .admin-company-selection-result-listing-item-actions {
    justify-content: flex-end;
    width: 20%;
  }
}

.admin-company-selection-result-listing-item-actions-action:first-child {
  margin-right: 2px;
}

.agency-code-form {
  position: relative;
  margin-bottom: 40px;
}

.agency-code-form .btn,
.agency-code-form .button {
  position: absolute;
  right: 0;
  top: 36px;
  height: 40px;
  border-radius: 0;
  line-height: 24px;
}

.dashboard-links-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #d1d1d1;
  margin-bottom: 30px;
  transition: 0.2s ease-in-out;
}

.dashboard-links-item::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #cbecf9;
  transition: 0.2s ease-in-out;
}

.dashboard-links-item::after {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  font-size: 1.875rem;
  margin-right: -5px;
}

@media (min-width: 767px) {
  .dashboard-links-item {
    padding: 26px;
  }
}

.dashboard-links-item:active, .dashboard-links-item:focus, .dashboard-links-item:hover {
  box-shadow: inset 0 0 0 2px #173a8c;
  border: 1px solid #173a8c;
  outline: none;
}

@keyframes pulse {
  0% {
    opacity: 0;
    margin-right: -5px;
  }
  100% {
    opacity: 1;
    margin-right: -15px;
  }
}

.dashboard-links-item:active::before, .dashboard-links-item:focus::before, .dashboard-links-item:hover::before {
  background-color: #173a8c;
}

.dashboard-links-item:active::after, .dashboard-links-item:focus::after, .dashboard-links-item:hover::after {
  animation: pulse .75s infinite;
}

.mobile-menu {
  width: 270px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: 0.2s ease-in-out;
  background: #0d2458;
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 400px) {
  .mobile-menu {
    width: 320px;
  }
}

.mobile-menu .mobile-menu-inner {
  position: relative;
}

.mobile-menu .mobile-menu-inner ul {
  transition: left 0.2s ease-in-out;
  position: absolute;
  top: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu .mobile-menu-inner li {
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile-menu .mobile-menu-inner .username {
  color: #fff;
  background-color: #06122c;
  font-weight: 700;
  padding: 14px 20px;
}

.mobile-menu .mobile-menu-inner a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 14px 20px;
  border: 0;
}

.mobile-menu .mobile-menu-inner a:active, .mobile-menu .mobile-menu-inner a:focus, .mobile-menu .mobile-menu-inner a:hover {
  border: 0;
  background: #06122c;
}

html.-js-menu-open {
  overflow: hidden;
}

html.-js-menu-open .mobile-menu {
  right: 0;
}

html.-js-menu-open .mobile-menu .mobile-menu-inner {
  position: relative;
}

html.-js-menu-open .mobile-menu[data-current="0"] ul[data-position="0"] {
  left: 0;
}

html.-js-menu-open .mobile-menu[data-current="0"] ul[data-position="1"] {
  left: 100%;
}

html.-js-menu-open .mobile-menu[data-current="0"] ul[data-position="2"] {
  left: 100%;
}

html.-js-menu-open .mobile-menu[data-current="1"] ul[data-position="0"] {
  left: -100%;
}

html.-js-menu-open .mobile-menu[data-current="1"] ul[data-position="1"] {
  left: 0;
}

html.-js-menu-open .mobile-menu[data-current="1"] ul[data-position="2"] {
  left: 100%;
}

html.-js-menu-open .mobile-menu[data-current="2"] ul[data-position="0"] {
  left: -100%;
}

html.-js-menu-open .mobile-menu[data-current="2"] ul[data-position="1"] {
  left: -100%;
}

html.-js-menu-open .mobile-menu[data-current="2"] ul[data-position="2"] {
  left: 0;
}

.page-container {
  position: relative;
  height: 100%;
  right: 0;
  transition: 0.2s ease-in-out;
}

.js-blocker {
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  top: 0;
  right: 0;
  transition: 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.4);
}

html.-js-menu-open .page-container {
  right: 270px;
  overflow: hidden;
}

@media (min-width: 400px) {
  html.-js-menu-open .page-container {
    right: 320px;
  }
}

html.-js-menu-open .js-blocker {
  z-index: 2;
  right: 270px;
  opacity: 1;
  visibility: visible;
}

@media (min-width: 400px) {
  html.-js-menu-open .js-blocker {
    right: 320px;
  }
}

.modal-admin-insurance-company {
  position: relative;
  background: #fff;
  max-width: 720px;
  padding: 0 20px 20px;
  margin: 0 auto;
}

@media (min-width: 767px) {
  .modal-admin-insurance-company {
    padding: 0 45px 20px;
  }
}

.modal-admin-insurance-company-header {
  background: #cbecf9;
  padding: 20px 40px 20px 45px;
  font-weight: 700;
  margin: 20px -20px;
}

@media (min-width: 500px) {
  .modal-admin-insurance-company-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 767px) {
  .modal-admin-insurance-company-header {
    margin: 20px -45px;
  }
}

.modal-admin-insurance-company .btn,
.modal-admin-insurance-company .button {
  margin-top: 20px;
}

.modal-admin-tour-operator {
  position: relative;
  background: #fff;
  max-width: 720px;
  padding: 0 20px 20px;
  margin: 0 auto;
}

@media (min-width: 767px) {
  .modal-admin-tour-operator {
    padding: 0 45px 20px;
  }
}

.modal-admin-tour-operator-header {
  background: #cbecf9;
  padding: 20px 40px 20px 45px;
  font-weight: 700;
  margin: 20px -20px;
}

@media (min-width: 500px) {
  .modal-admin-tour-operator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 767px) {
  .modal-admin-tour-operator-header {
    margin: 20px -45px;
  }
}

.modal-admin-tour-operator .btn,
.modal-admin-tour-operator .button {
  margin-top: 20px;
}

.modal-company-selections {
  position: relative;
  background: #fff;
  max-width: 720px;
  padding: 0 20px 20px;
  margin: 0 auto;
}

@media (min-width: 767px) {
  .modal-company-selections {
    padding: 0 45px 20px;
  }
}

.modal-company-selections-header {
  background: #cbecf9;
  padding: 20px 40px 20px 45px;
  font-weight: 700;
  margin: 20px -20px;
}

@media (min-width: 500px) {
  .modal-company-selections-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 767px) {
  .modal-company-selections-header {
    margin: 20px -45px;
  }
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 0;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 34px;
  top: 6px;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    transform-origin: 0;
  }
  .mfp-arrow-right {
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.modal {
  position: relative;
  background: #fff;
  max-width: 720px;
  padding: 0 20px 20px;
  margin: 0 auto;
}

@media (min-width: 767px) {
  .modal {
    padding: 0 45px 20px;
  }
}

.modal-header {
  background: #cbecf9;
  padding: 20px 40px 20px 45px;
  font-weight: 700;
  margin: 20px -20px;
}

.-failure .modal-header {
  background: #e21836;
}

.-success .modal-header {
  background: #25931b;
}

.-failure .modal-header,
.-success .modal-header {
  color: #fff;
}

@media (min-width: 500px) {
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 767px) {
  .modal-header {
    margin: 20px -45px;
  }
}

.modal-body-context {
  margin-bottom: 20px;
}

.modal-body-context:last-child {
  margin-bottom: 0;
}

.modal.-failure .mfp-close, .modal.-success .mfp-close {
  color: #fff;
}

.registration-breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.registration-breadcrumb-step {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #3a73c5 0%, #1c4195 100%);
  color: #fff;
  font-weight: 700;
  padding: 10px 20px;
  border: 0;
  border-radius: 1000px;
  margin: 0 3px;
}

@media (min-width: 767px) {
  .registration-breadcrumb-step {
    padding: 14px 34px;
  }
}

.registration-breadcrumb-step.-active {
  pointer-events: none;
}

.registration-breadcrumb-step.-active::after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 0;
  border-color: #1c4195 transparent transparent;
  margin-left: -9px;
}

.registration-breadcrumb-step.-disabled {
  opacity: .5;
}

.registration-breadcrumb-step.-complete {
  background: linear-gradient(to bottom, #76c53a 0%, #25931b 100%);
}

.registration-breadcrumb-step.-complete::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 21px;
  font-size: 1.3125rem;
  margin-right: 8px;
}

.registration-breadcrumb-step.-complete:active, .registration-breadcrumb-step.-complete:focus, .registration-breadcrumb-step.-complete:hover {
  background: linear-gradient(to bottom, #25931b 0%, #76c53a 100%);
}

.registration-breadcrumb-step:active, .registration-breadcrumb-step:focus, .registration-breadcrumb-step:hover {
  color: #fff;
  border: 0;
}

.registration-company-selection-tabs {
  text-align: center;
}

.registration-company-selection-tabs-inner {
  display: inline-flex;
  background: #cbecf9;
  padding: 6px;
  margin: 0 auto 20px;
  border-radius: 1000px;
}

.registration-company-selection-tabs-content .btn.-large,
.registration-company-selection-tabs-content .button.-large {
  margin-top: 30px;
}

.registration-company-selection .tab {
  padding: 8px 9px;
  position: relative;
  display: flex;
  align-items: center;
}

.registration-company-selection .tab:first-child {
  border-radius: 1000px 0 0 1000px;
}

.registration-company-selection .tab:last-child {
  border-radius: 0 1000px 1000px 0;
}

.registration-company-selection .tab[data-active='true'] {
  color: #fff;
  background: #173a8c;
  pointer-events: none;
}

@media (min-width: 767px) {
  .registration-company-selection .tab[data-active='true'] {
    padding: 8px 20px;
  }
}

.registration-company-selection .tab[data-active='true']::after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 0;
  border-color: #1c4195 transparent transparent;
  margin-left: -9px;
}

.registration-company-selection .tab[data-active='false'] {
  background-color: #fff;
  color: #173a8c;
}

@media (min-width: 767px) {
  .registration-company-selection .tab[data-active='false'] {
    padding: 8px 20px;
  }
}

.registration-company-selection .tab[data-active='false']:active, .registration-company-selection .tab[data-active='false']:focus, .registration-company-selection .tab[data-active='false']:hover {
  color: #fff;
  background: #102860;
}

@media (min-width: 500px) {
  .registration-company-selection-form {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 500px) {
  .registration-company-selection-form-type {
    width: 32%;
    margin-right: 14px;
  }
}

.registration-company-selection-form-keyword {
  position: relative;
}

@media (min-width: 500px) {
  .registration-company-selection-form-keyword {
    width: 68%;
  }
}

.registration-company-selection-form-keyword .clear-filter {
  position: absolute;
  display: flex;
  align-items: center;
  right: 5px;
  top: 41px;
  color: #3b3b3b;
  background-color: #d1d1d1;
  padding: 8px;
  border: 0;
  border-radius: 1000px;
  font-size: 13px;
  font-size: 0.8125rem;
}

.registration-company-selection-form-keyword .clear-filter::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  font-size: 0.75rem;
  margin-top: 2px;
  margin-right: 4px;
}

.registration-company-selection-form-keyword .clear-filter:active, .registration-company-selection-form-keyword .clear-filter:focus, .registration-company-selection-form-keyword .clear-filter:hover {
  border: 0;
  background-color: #b8b8b8;
}

.registration-company-selection-result-bar {
  background: #cbecf9;
  padding: 10px 20px;
  font-weight: 700;
  margin: 20px -20px;
}

@media (min-width: 500px) {
  .registration-company-selection-result-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 767px) {
  .registration-company-selection-result-bar {
    padding: 10px 100px;
    margin: 20px -100px;
  }
}

@media (max-width: 499px) {
  .registration-company-selection-result-bar-number {
    margin-bottom: 10px;
  }
}

.registration-company-selection-result-bar-actions .btn,
.registration-company-selection-result-bar-actions .button {
  font-size: 12px;
}

.registration-company-selection-result-bar-actions .btn:first-child,
.registration-company-selection-result-bar-actions .button:first-child {
  margin-right: 5px;
}

.registration-company-selection-result-listing-item {
  padding: 5px 0;
  border-top: 1px solid #d1d1d1;
}

@media (min-width: 400px) {
  .registration-company-selection-result-listing-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.registration-company-selection-result-listing-item:first-child {
  border: 0;
}

.registration-company-selection-result-listing-item-name {
  width: 80%;
  margin-bottom: 10px;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media (min-width: 767px) {
  .registration-company-selection-result-listing-item-name {
    padding-right: 20px;
    margin: 0;
  }
}

.registration-company-selection-result-listing-item-actions {
  display: flex;
  align-items: center;
}

@media (min-width: 767px) {
  .registration-company-selection-result-listing-item-actions {
    justify-content: flex-end;
    width: 20%;
  }
}

.registration-company-selection-result-listing-item-actions .added {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #25931b;
  margin-right: 12px;
}

.registration-company-selection-result-listing-item-actions .added::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-size: 1.125rem;
  margin-right: 4px;
}

.registration-promo-box {
  position: relative;
  text-align: center;
  padding: 20px;
  border: 1px solid #d1d1d1;
  margin-bottom: 30px;
}

@media (min-width: 767px) {
  .registration-promo-box {
    padding: 26px;
  }
}

.registration-promo-box .btn,
.registration-promo-box .button {
  max-width: 240px;
  margin: 0 auto;
}

.registration-promo-box::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #cbecf9;
}

.registration-promo-why-register .h3 {
  position: relative;
  padding-bottom: 20px;
}

.registration-promo-why-register .h3::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background: #d1d1d1;
}

.site-footer {
  background: #fff;
  margin: 50px 0 0;
}

.site-footer-inner,
.site-footer .copyright {
  max-width: 1000px;
  padding: 20px 20px 0;
  margin: 0 auto;
}

.site-footer-inner:last-child,
.site-footer .copyright:last-child {
  padding-bottom: 20px;
}

@media (min-width: 767px) {
  .site-footer-inner:last-child,
  .site-footer .copyright:last-child {
    padding-bottom: 50px;
  }
}

@media (min-width: 1040px) {
  .site-footer-inner,
  .site-footer .copyright {
    padding: 20px 0 0;
  }
  .site-footer-inner:first-child,
  .site-footer .copyright:first-child {
    padding-top: 50px;
  }
  .site-footer-inner:last-child,
  .site-footer .copyright:last-child {
    padding-bottom: 50px;
  }
}

.site-footer p {
  font-size: 11px;
  font-size: 0.6875rem;
}

@media (min-width: 767px) {
  .site-footer .copyright .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.site-footer .copyright .text-side {
  margin-bottom: 10px;
}

@media (min-width: 767px) {
  .site-footer .copyright .text-side {
    margin-bottom: 0;
    flex: 0 0 50%;
  }
}

@media (min-width: 767px) {
  .site-footer .copyright .link-side {
    flex: 0 0 50%;
    text-align: right;
  }
}

.site-footer .copyright .link-side > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 767px) {
  .site-footer .copyright .link-side > ul {
    display: flex;
    justify-content: flex-end;
  }
}

.site-footer .copyright .link-side > ul li {
  margin-bottom: 5px;
}

@media (min-width: 767px) {
  .site-footer .copyright .link-side > ul li {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .site-footer .copyright .link-side > ul li:last-child {
    margin-right: 0;
  }
}

.site-header {
  background: #fff;
  margin: 0 0 20px;
}

.site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  padding: 10px 20px;
  margin: 0 auto;
}

@media (min-width: 767px) {
  .site-header-inner {
    padding: 20px;
  }
}

@media (min-width: 1040px) {
  .site-header-inner {
    padding: 20px 0;
  }
}

.site-header-logo {
  display: block;
  border: 0;
}

@media (max-width: 799px) {
  .site-header-logo {
    width: 110px;
  }
}

.site-header-logo:active, .site-header-logo:focus, .site-header-logo:hover {
  border: 0;
}

.site-header-mobile-menu-trigger {
  display: flex;
  align-items: center;
  border: 0;
}

.site-header-mobile-menu-trigger::before {
  content: "";
  display: inline-block;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  font-size: 1.25rem;
  margin-right: 10px;
}

@media (min-width: 767px) {
  .site-header-mobile-menu-trigger {
    display: none;
  }
}

.site-header-mobile-menu-trigger:active, .site-header-mobile-menu-trigger:focus, .site-header-mobile-menu-trigger:hover {
  border: 0;
}

.site-header-nav {
  position: relative;
}

@media (max-width: 799px) {
  .site-header-nav {
    display: none;
  }
}

.site-header-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.site-header-nav ul > li {
  margin-left: 10px;
}

.site-header-nav ul > li:first-child::before {
  display: none;
}

.site-header-nav ul > li::before {
  content: '|';
  margin-right: 6px;
  color: #919191;
}

.tabs {
  display: flex;
  max-width: 720px;
  margin: 0 auto;
}

.tabs-content {
  display: none;
}

.tabs-content.-current {
  display: block;
}

.tab {
  padding: 20px;
  font-weight: 700;
  border: 0;
  background: #173a8c;
  color: #fff;
  transition: 0.2s ease-in-out;
}

@media (min-width: 767px) {
  .tab {
    padding: 20px 30px;
  }
}

.tab:active, .tab:focus, .tab:hover {
  border: 0;
  color: #fff;
}

.tab[data-active='true'] {
  color: #173a8c;
  background: #fff;
  pointer-events: none;
}

.tab[data-active='false']:active, .tab[data-active='false']:focus, .tab[data-active='false']:hover {
  border: 0;
  color: #fff;
}

.training-video {
  max-width: 720px;
  background-color: #000;
  margin: 0 auto;
}

.travel-retailer-employee-listing-item-mobile-header {
  display: block;
  padding: 5px;
  font-weight: 700;
  font-size: 12px;
  font-size: 0.75rem;
  background-color: #d1d1d1;
  margin-bottom: 4px;
}

@media (min-width: 500px) {
  .travel-retailer-employee-listing-item-mobile-header {
    display: none;
  }
}

.travel-retailer-employee-listing-header {
  display: none;
}

@media (min-width: 500px) {
  .travel-retailer-employee-listing-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    background: #d1d1d1;
    font-weight: 700;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.travel-retailer-employee-listing-header-name {
  width: 25%;
  padding-left: 5px;
}

.travel-retailer-employee-listing-header-email {
  width: 45%;
  padding-left: 10px;
}

.travel-retailer-employee-listing-header-date {
  width: 17%;
  padding-right: 5px;
  text-align: right;
}

.travel-retailer-employee-listing-item {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
}

@media (min-width: 500px) {
  .travel-retailer-employee-listing-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0;
  }
  .travel-retailer-employee-listing-item > div {
    display: flex;
    align-items: center;
  }
}

.travel-retailer-employee-listing-item:last-child {
  border: 0;
}

.travel-retailer-employee-listing-item:nth-child(even) {
  background: #f3f2f2;
}

.travel-retailer-employee-listing-item-content {
  padding: 0 5px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 12px;
  font-size: 0.75rem;
}

@media (min-width: 500px) {
  .travel-retailer-employee-listing-item-content {
    margin: 0;
  }
}

@media (min-width: 767px) {
  .travel-retailer-employee-listing-item-content {
    padding: 0 10px;
  }
}

@media (min-width: 500px) {
  .travel-retailer-employee-listing-item-name {
    width: 25%;
  }
}

@media (min-width: 500px) {
  .travel-retailer-employee-listing-item-email {
    width: 45%;
  }
}

@media (min-width: 500px) {
  .travel-retailer-employee-listing-item-date {
    width: 17%;
    text-align: right;
    justify-content: flex-end;
  }
}

.travel-retailer-employee-training-link {
  position: relative;
  margin-bottom: 40px;
}

.travel-retailer-employee-training-link .send-button {
  position: absolute;
  right: 0;
  top: 36px;
  height: 40px;
  border-radius: 0;
  line-height: 24px;
}

.travel-retailer-employee-training-link-url {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background: #f3f2f2;
}

.travel-retailer-employee-training-link-url input {
  flex: 1 1 auto;
  border: 0;
  margin: 0 10px 0 0;
  padding: 0;
  width: auto;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  background: none;
}
