// Icomoon variables
$icons: (
  copy: '\e907',
  caret: '\e906',
  circle: '\e905',
  formcheck: '\e904',
  plus: '\e903',
  close: '\e901',
  check: '\e902',
  menu: '\e900'
);

[class^='icon-'],
[class*=' icon-'] {

  &::before {
    @include icomoon;
  }
}

// Site Icons
//
// Icons:
// copy        : icon-copy
// caret       : icon-caret
// circle      : icon-circle
// formcheck   : icon-formcheck
// plus        : icon-plus
// close       : icon-close
// check       : icon-check
// menu        : icon-menu

//
// Styleguide Icons.Site Icons
