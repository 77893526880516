// Mobile Menu
//
// .-modifier - Modifier description
//
// Markup: mobile-menu.twig
//
// Styleguide Components.Mobile Menu

// sass-lint:disable-all

.mobile-menu {
  width: 270px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: $ts $te;
  background: primary-color(blue, shade1);
  @include rem(font-size, 16px);

  @include breakpoint(400px) {
    width: 320px;
  }

  .mobile-menu-inner {
    position: relative;

    ul {
      transition: left $ts $te;
      position: absolute;
      top: 0;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: block;
      border-bottom: 1px solid rgba(primary-color(white), 0.2);
    }

    .username {
      color: primary-color(white);
      background-color: darken(primary-color(blue, shade1), 10%);
      @include font-weight(bold);
      padding: 14px 20px;
    }

    a {
      display: block;
      color: primary-color(white);
      text-decoration: none;
      padding: 14px 20px;
      border: 0;

      @include hover {
        border: 0;
        background: darken(primary-color(blue, shade1), 10%);
      }
    }
  }
}

html.-js-menu-open {
  overflow: hidden;

  .mobile-menu {
    right: 0;

    .mobile-menu-inner {
      position: relative;
    }

    &[data-current="0"] {

      ul[data-position="0"] {
        left: 0;
      }

      ul[data-position="1"] {
        left: 100%;
      }

      ul[data-position="2"] {
        left: 100%;
      }
    }

    &[data-current="1"] {

      ul[data-position="0"] {
        left: -100%;
      }

      ul[data-position="1"] {
        left: 0;
      }

      ul[data-position="2"] {
        left: 100%;
      }
    }

    &[data-current="2"] {

      ul[data-position="0"] {
        left: -100%;
      }

      ul[data-position="1"] {
        left: -100%;
      }

      ul[data-position="2"] {
        left: 0;
      }
    }
  }
}


// Blocker and Page Movement
.page-container {
  position: relative;
  height: 100%;
  right: 0;
  transition: $ts $te;
}

.js-blocker {
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  top: 0;
  right: 0;
  transition: $ts $te;
  background: rgba(#000, 0.4);
}

html.-js-menu-open {

  .page-container {
    right: 270px;
    overflow: hidden;

    @include breakpoint(400px) {
      right: 320px;
    }
  }

  .js-blocker {
    z-index: 2;
    right: 270px;
    opacity: 1;
    visibility: visible;

    @include breakpoint(400px) {
      right: 320px;
    }
  }
}
