// Admin Company Selection
//
// .-modifier - Modifier description
//
// Markup: admin-company-selection.twig
//
// Styleguide Components.Admin Company Selection
.admin-company-selection {

  &-form {

    &-keyword {
      position: relative;

      .clear-filter {
        position: absolute;
        display: flex;
        align-items: center;
        right: 5px;
        top: 41px;
        color: primary-color(gray, shade2);
        background-color: primary-color(gray);
        padding: 8px;
        border: 0;
        border-radius: 1000px;
        @include rem(font-size, 13px);
        @include icon(close) {
          @include rem(font-size, 12px);
          margin-top: 2px;
          margin-right: 4px;
        }

        @include hover {
          border: 0;
          background-color: darken(primary-color(gray), 10%);
        }
      }
    }
  }

  &-result-bar {
    background: secondary-color(light-blue, tint1);
    padding: 10px 20px;
    @include font-weight(bold);
    margin: 20px -20px;

    @include from(7) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 100px;
      margin: 20px -100px;
    }

    &-number {
      @include to(7) {
        margin-bottom: 10px;
      }
    }

    &-actions {
      .btn,
      .button {
        font-size: 12px;
      }
    }
  }

  &-result-listing {
    &-item {
      padding: 5px 0;
      border-top: 1px solid primary-color(gray);

      @include from(5) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:first-child {
        border: 0;
      }

      &-name {
        width: 80%;
        margin-bottom: 10px;
        @include rem(font-size, 13px);

        @include from(7) {
          padding-right: 20px;
          margin: 0;
        }
      }

      &-actions {
        display: flex;
        align-items: center;

        @include from(7) {
          justify-content: flex-end;
          width: 20%;
        }

        &-action {
          &:first-child {
            margin-right: 2px;
          }
        }
      }
    }
  }
}
