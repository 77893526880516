// Registration Breadcrumb
//
// .-modifier - Modifier description
//
// Markup: registration-breadcrumb.twig
//
// Styleguide Components.Registration Breadcrumb
.registration-breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  &-step {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #3a73c5 0%, #1c4195 100%);
    color: primary-color(white);
    @include font-weight(bold);
    padding: 10px 20px;
    border: 0;
    border-radius: 1000px;
    margin: 0 3px;

    @include from(7) {
      padding: 14px 34px;
    }

    &.-active {
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 9px 0;
        border-color: #1c4195 transparent transparent;
        margin-left: -9px;
      }
    }

    &.-disabled {
      opacity: .5;
    }

    &.-complete {
      background: linear-gradient(to bottom, #76c53a 0%, #25931b 100%);

      @include icon(check) {
        @include rem(font-size, 21px);
        margin-right: 8px;
      }

      @include hover {
        background: linear-gradient(to bottom, #25931b 0%, #76c53a 100%);
      }
    }

    @include hover {
      color: primary-color(white);
      border: 0;
    }
  }
}
