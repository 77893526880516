// Modal (standard)
//
// .-modifier - Modifier description
//
// Markup: modal.twig
//
// Styleguide Components.Modal
.modal {
  position: relative;
  background: primary-color(white);
  max-width: 720px;
  padding: 0 20px 20px;
  margin: 0 auto;

  @include from(7) {
    padding: 0 45px 20px;
  }

  &-header {
    background: secondary-color(light-blue, tint1);
    padding: 20px 40px 20px 45px;
    @include font-weight(bold);
    margin: 20px -20px;

    .-failure & {
      background: primary-color(red);
    }

    .-success & {
      background: secondary-color(green);
    }

    .-failure &,
    .-success & {
      color: primary-color(white);
    }

    @include from(5) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include from(7) {
      margin: 20px -45px;
    }
  }

  &-body {

    &-context {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.-failure,
  &.-success {

    .mfp-close {
      color: primary-color(white);
    }
  }
}
