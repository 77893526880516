// Travel Retailer Employee Training Link
//
// .-modifier - Modifier description
//
// Markup: travel-retailer-employee-training-link.twig
//
// Styleguide Components.Travel Retailer Employee Training Link

.travel-retailer-employee-training-link {
  position: relative;
  margin-bottom: 40px;

  .send-button {
    position: absolute;
    right: 0;
    top: 36px;
    height: 40px;
    border-radius: 0;
    line-height: 24px;
  }

  &-url {
    display: flex;
    align-items: center;
    padding: 4px 10px;
    background: primary-color(gray, tint1);

    input {
      flex: 1 1 auto;
      border: 0;
      margin: 0 10px 0 0;
      padding: 0;
      width: auto;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      background: none;
    }
  }
}
