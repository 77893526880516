// Dashboard Links
//
// .-modifier - Modifier description
//
// Markup: dashboard-links.twig
//
// Styleguide Components.Dashboard Links
.dashboard-links {
  &-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid primary-color(gray);
    margin-bottom: 30px;
    transition: $ts $te;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: secondary-color(light-blue, tint1);
      transition: $ts $te;
    }

    @include icon(caret, after) {
      @include rem(font-size, 30px);
      margin-right: -5px;
    }

    @include from(7) {
      padding: 26px;
    }

    @include hover {
      box-shadow: inset 0 0 0 2px primary-color(blue);
      border: 1px solid primary-color(blue);
      outline: none;

      @keyframes pulse {
        0% {
          opacity: 0;
          margin-right: -5px;
        }

        100% {
          opacity: 1;
          margin-right: -15px;
        }
      }

      &::before {
        background-color: primary-color(blue);
      }

      &::after {
        animation: pulse .75s infinite;
      }
    }
  }
}
