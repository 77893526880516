// Registration Company Selection
//
// .-modifier - Modifier description
//
// Markup: registration-company-selection.twig
//
// Styleguide Components.Registration Company Selection
.registration-company-selection {
  &-tabs {
    text-align: center;

    &-inner {
      display: inline-flex;
      background: secondary-color(light-blue, tint1);
      padding: 6px;
      margin: 0 auto 20px;
      border-radius: 1000px;
    }

    &-content {
      .btn,
      .button {
        &.-large {
          margin-top: 30px;
        }
      }
    }
  }

  .tab {
    padding: 8px 9px;
    position: relative;
    display: flex;
    align-items: center;

    &:first-child {
      border-radius: 1000px 0 0 1000px;
    }

    &:last-child {
      border-radius: 0 1000px 1000px 0;
    }

    //modifiers
    &[data-active='true'] {

      color: primary-color(white);
      background: primary-color(blue);
      pointer-events: none;

      @include from(7) {
        padding: 8px 20px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 9px 0;
        border-color: #1c4195 transparent transparent;
        margin-left: -9px;
      }
    }

    &[data-active='false'] {
      background-color: primary-color(white);
      color: primary-color(blue);

      @include from(7) {
        padding: 8px 20px;
      }

      @include hover {
        color: primary-color(white);
        background: darken(primary-color(blue), 10%);
      }
    }
  }

  &-form {
    @include from(5) {
      display: flex;
      justify-content: space-between;
    }

    &-type {
      @include from(5) {
        width: 32%;
        margin-right: 14px;
      }
    }

    &-keyword {
      position: relative;
      @include from(5) {
        width: 68%;
      }

      .clear-filter {
        position: absolute;
        display: flex;
        align-items: center;
        right: 5px;
        top: 41px;
        color: primary-color(gray, shade2);
        background-color: primary-color(gray);
        padding: 8px;
        border: 0;
        border-radius: 1000px;
        @include rem(font-size, 13px);
        @include icon(close) {
          @include rem(font-size, 12px);
          margin-top: 2px;
          margin-right: 4px;
        }

        @include hover {
          border: 0;
          background-color: darken(primary-color(gray), 10%);
        }
      }
    }
  }

  &-result-bar {
    background: secondary-color(light-blue, tint1);
    padding: 10px 20px;
    @include font-weight(bold);
    margin: 20px -20px;

    @include from(5) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include from(7) {
      padding: 10px 100px;
      margin: 20px -100px;
    }

    &-number {
      @include to(4) {
        margin-bottom: 10px;
      }
    }

    &-actions {
      .btn,
      .button {
        font-size: 12px;

        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }

  &-result-listing {
    &-item {
      padding: 5px 0;
      border-top: 1px solid primary-color(gray);

      @include from(4) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:first-child {
        border: 0;
      }

      &-name {
        width: 80%;
        margin-bottom: 10px;
        @include rem(font-size, 13px);

        @include from(7) {
          padding-right: 20px;
          margin: 0;
        }
      }

      &-actions {
        display: flex;
        align-items: center;

        @include from(7) {
          justify-content: flex-end;
          width: 20%;
        }

        .added {
          display: flex;
          align-items: center;
          @include rem(font-size, 12px);
          @include font-weight(bold);
          color: secondary-color(green);
          margin-right: 12px;
          @include icon(check) {
            @include rem(font-size, 18px);
            margin-right: 4px;
          }
        }
      }
    }
  }
}
