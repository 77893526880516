// Tabs
//
// .-modifier - Modifier description
//
// Markup: tabs.twig
//
// Styleguide Components.Tabs
.tabs {
  display: flex;
  max-width: 720px;
  margin: 0 auto;

  &-content {
    display: none;

    &.-current {
      display: block;
    }
  }
}

.tab {
  padding: 20px;
  @include font-weight(bold);
  border: 0;
  background: primary-color(blue);
  color: primary-color(white);
  transition: $ts $te;

  @include from(7) {
    padding: 20px 30px;
  }

  @include hover {
    border: 0;
    color: primary-color(white);
  }

  &[data-active='true'] {
    color: primary-color(blue);
    background: primary-color(white);
    pointer-events: none;
  }

  &[data-active='false'] {

    @include hover {
      border: 0;
      color: primary-color(white);
    }

  }
}
