// Agency Code Form
//
// .-modifier - Modifier description
//
// Markup: agency-code-form.twig
//
// Styleguide Components.Agency Code Form
.agency-code-form {
  position: relative;
  margin-bottom: 40px;

  .btn,
  .button {
    position: absolute;
    right: 0;
    top: 36px;
    height: 40px;
    border-radius: 0;
    line-height: 24px;
  }
}
