// Icomoon required styles
@mixin icomoon {
  display: inline-block;
  font-family: 'icomoon' !important; // sass-lint:disable-line no-important
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
