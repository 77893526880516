// Site Header
//
// Markup: site-header.twig
//
// Styleguide Components.Site Header
.site-header {
  background: primary-color(white);
  margin: 0 0 20px;

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    padding: 10px 20px;
    margin: 0 auto;

    @include from(7) {
      padding: 20px;
    }

    @include from(10) {
      padding: 20px 0;
    }
  }

  &-logo {
    display: block;
    border: 0;

    @include to(7) {
      width: 110px;
    }

    @include hover {
      border: 0;
    }
  }

  &-mobile-menu-trigger {
    display: flex;
    align-items: center;
    border: 0;
    @include icon(menu) {
      @include rem(font-size, 20px);
      margin-right: 10px;
    }
    @include from(7) {
      display: none;
    }

    @include hover {
      border: 0;
    }
  }

  &-nav {
    position: relative;
    @include to(7) {
      display: none;
    }

    ul {
      @include kill-bullets;
      display: flex;

      > li {
        margin-left: 10px;

        &:first-child {
          // sass-lint:disable nesting-depth
          &::before {
            display: none;
          }
        }

        &::before {
          content: '|';
          margin-right: 6px;
          color: primary-color(gray, shade1);
        }
      }
    }
  }
}
